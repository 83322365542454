import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrivacyPolicy from '../PrivacyPolicy'

import { SnackbarContent, Button, Modal, Dialog, DialogContent } from '@material-ui/core';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      privacyPolicy: undefined,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
    window.stop();
  };

  handleClose() {
    this.setState({ open: false });
    location.reload();
  };

  render() {
    const { company, dataController } = this.props;
    const { privacyPolicy } = this.state;

    return (
      <div>
        <hr />
        <div className="footer wrap">
          <p>Save our Future is a Forward Democracy and Best for Britain project.</p>
          <p>Best for Britain is the campaign name of UK-EU Open Policy Limited (08188502) registered at International House, 24 Holborn Viaduct, London EC1A 2BN and is registered with The Electoral Commission.</p>
          <p>&copy; 2018 <a href="http://forwarddemocracy.com" target="_blank">Forward Democracy Limited</a> is registered company no. 11707599</p>
          <p><a href="#" onClick={this.handleOpen}>Privacy Policy</a></p>

          <Dialog
          open={this.state.open}
          onClose={this.handleClose}
        >
            <DialogContent>
              <PrivacyPolicy />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  name: PropTypes.string,
  message: PropTypes.string
};

Footer.defaultProps = {
  company: 'Makosoft Ltd',
  dataController: 'James Freedman, 37a Hugon Road, London SW6 3ER, privacy@makosoft.co.uk'
};

export default Footer;
