import React from 'react';

import Img from 'gatsby-image';

import { Grid, Button } from '@material-ui/core';

const ClickImage = ({label, fixed, url}) => (
  <Grid item xs={6} sm={4}>
    <Button variant="outlined" color="primary" href={url} style={{border: "0px"}}>
      <div>
        <Img
          fixed={fixed.childImageSharp.fixed}
          alt={`${label} logo`}
          href={url}
        />
      </div>

      </Button>
  </Grid>
)

export default ClickImage
