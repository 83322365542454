import React from 'react';
// import PropTypes from 'prop-types';
import emergence from 'emergence.js';

import { Grid, Paper } from '@material-ui/core';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Navi from '../Navi';
import Footer from '../Footer';
import { siteMetadata } from '../../../gatsby-config';

import ClickImage from './ClickImage';

import './layout.scss';

export const height50Image = graphql`
  fragment height50Image on File {
    childImageSharp {
      fixed(width: 120) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

const query = graphql`
  query LayoutQuery {
    banner: file(name: { eq: "title" }) {
      childImageSharp {
        fixed(width: 310) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    techForUK: file(name: { eq: "tech_for_uk" }) {
      ...height50Image
    }
    forwardDemocracy: file(name: { eq: "forward_democracy" }) {
      ...height50Image
    }
    bestForBritain: file(name: { eq: "best_for_britain" }) {
      ...height50Image
    }
    finalSay: file(name: { eq: "final_say" }) {
      ...height50Image
    }
    myEU: file(name: { eq: "my_eu" }) {
      ...height50Image
    }
    compareTheBrexit: file(name: { eq: "compare_the_brexit" }) {
      ...height50Image
    }
    revokeInfo: file(name: { eq: "revoke_info" }) {
      ...height50Image
    }
  }
`;

class Layout extends React.Component {
  componentDidMount() {
    emergence.init();
  }

  componentDidUpdate() {
    emergence.init();
  }

  render() {
    const { children, data } = this.props;

    return (
      <div className="app">
        <div className="headerImage wrap">
          <Img fixed={data.banner.childImageSharp.fixed} />
        </div>

        {children}

        <section className="wrap partners">
          <h3>Partners</h3>
          <Grid container alignItems="center" className="logo-grid">
            <ClickImage url="https://techforuk.com/?utm_source=saveourfuture&utm_campaign=saveourfuture" label="Tech for UK" fixed={data.techForUK} />
            <ClickImage url="http://forwarddemocracy.com/?utm_source=saveourfuture&utm_campaign=saveourfuture" label="Forward Democracy" fixed={data.forwardDemocracy} />
            <ClickImage url="http://bestforbritain.org?utm_source=saveourfuture&utm_campaign=saveourfuture" label="Best for Britain" fixed={data.bestForBritain} />
          </Grid>
        </section>
        <section className="wrap projects">
          <h3>Associated Projects</h3>
          <Grid container alignItems="center" className="logo-grid">
            <ClickImage url="https://finalsay.app/?utm_source=saveourfuture&utm_campaign=saveourfuture" label="Final Say" fixed={data.finalSay} />
            <ClickImage url="https://www.myeu.uk/?utm_source=saveourfuture&utm_campaign=saveourfuture" label="MyEU" fixed={data.myEU} />
            <ClickImage url="https://comparethebrexit.com/?utm_source=saveourfuture&utm_campaign=saveourfuture" label="Compare the Brexit" fixed={data.compareTheBrexit} />
            <ClickImage url="https://revoke.info/?utm_source=saveourfuture&utm_campaign=saveourfuture" label="Revoke.info" fixed={data.revokeInfo} />
          </Grid>
        </section>
        <Footer />
      </div>
    );
  }
}

Layout.propTypes = {};

export default props => (
  <StaticQuery
    query={query}
    render={data => <Layout data={data} {...props} />}
  />
);
