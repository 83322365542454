import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { siteMetadata } from '../../../gatsby-config';

const Meta = ({ title, description, whatsAppMessage, location, shareImage, metaRefresh }) => {
  const siteTitle = get(siteMetadata, 'title');
  const pageTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  const shareTitle = title ? title : siteTitle
  const metaTags = [
    { name: 'description', content: description },
    { name: 'keywords', content: get(siteMetadata, 'keywords') },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: `@${get(siteMetadata, 'twitter')}` },
    { name: 'twitter:title', content: shareTitle },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:image',
      content: `${get(siteMetadata, 'siteUrl')}/img/share/share-${shareImage}.png?v=20190505`,
    },
    { name: 'twitter:domain', content: get(siteMetadata, 'siteUrl') },
    { name: 'twitter:creator', content: get(siteMetadata, 'author') },

    { property: 'og:site_name', content: siteTitle },
    { property: 'og:title', content: shareTitle },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:url',
      content: get(siteMetadata, 'siteUrl') + (location ? location.pathname : '/'),
    },
    {
      property: 'og:image',
      content: `${get(siteMetadata, 'siteUrl')}/img/share/share-${shareImage}.png?v=20190505`,
    },
    /* Test if there is a WhatsApp message present *
     * If so, add a meta redirect to the Meta array *
     * 'http-equiv' has to be included in quotes to be *
     * picked up as valid (camel case does not work). *
     * Ternary will append meta array elements if present, *
     * or empty set which Helmet ignores */
    ... whatsAppMessage ? [{
      'http-equiv': 'refresh',
      content: `4; url=https://api.whatsapp.com/send?text=${encodeURIComponent(
        whatsAppMessage
      ).replace(/%0A/g, '%0D%0A')}`
    }] : [],
    /* Alternative metarefresh, used by /demand */
    ... metaRefresh ? [{
      'http-equiv': 'refresh',
      content: metaRefresh
    }] : [],
  ];

  return (
    <Helmet
      title={pageTitle}
      meta={metaTags}
    />
  );
};

Meta.propTypes = {
  site: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  whatsAppMessage: PropTypes.string,
  location: PropTypes.object.isRequired,
  shareImage: PropTypes.string,
  metaRefresh: PropTypes.string,
};

Meta.defaultProps = {
  title: null,
  description: get(siteMetadata, 'description'),
  whatsAppMessage: null,
  metaRefresh: null,
  shareImage: 'default',
};

export default Meta;
